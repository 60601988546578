import { setCookie, getCookie } from './common/Cookies';

// Kulturio backend configuration
let currentConfig;
const configs = {
  "env": {
    "pcsApiUrl": process.env.REACT_APP_PCS_API_URL,
    "pcsApiKey": process.env.REACT_APP_PCS_API_KEY,
    "eKulturAppId": process.env.REACT_APP_EKULTUR_APP_ID,
    "eKulturLoginHost": process.env.REACT_APP_LOGIN_HOST,
    "eKulturApiGateway": process.env.REACT_APP_LOGIN_GATEWAY,
    "eKulturSameSite": process.env.REACT_APP_LOGIN_SAMESITE,
    "sendAuthTokenOnRequests": process.env.REACT_APP_SEND_AUTH_TOKEN_ON_REQUESTS
  },
  "development": {
    "pcsApiUrl": "https://dev-pcs.kulturio.org/",
    "pcsApiKey": "87e1eb21-e1ee-437c-bee0-8f7c7c3441b6",
    "eKulturAppId": "3b1fb453-6841-4792-aa34-c08dfde7643c",
    "eKulturLoginHost": "https://beta-login.ekultur.org",
    "eKulturApiGateway": "https://beta-apigateway.kulturio.org",
    "eKulturSameSite": true,
    "sendAuthTokenOnRequests": true
  },
  "production": {
    "pcsApiUrl": "https://pcs.kulturio.org/",
    "pcsApiKey": "87e1eb21-e1ee-437c-bee0-8f7c7c3441b6",    
    "eKulturAppId": "3b1fb453-6841-4792-aa34-c08dfde7643c",
    "eKulturLoginHost": "https://login.ekultur.org",
    "eKulturApiGateway": "https://apigateway.kulturio.org",
    "eKulturSameSite": true,
    "sendAuthTokenOnRequests": true
  }
}

// Get environment details based on URL if env data is not set
//if (process.env.REACT_APP_PCS_API_URL) {
//  currentConfig = configs["env"];
//} else 
if (window.location.hostname === "remote.kulturio.org") {
  currentConfig = configs["production"];
} else {
  currentConfig = configs["development"];
}

// Override environment details based on querystring - for testing purposes
const urlParams = new URLSearchParams(window.location.search);
const env = urlParams.get('env');
if (env) { setCookie("env", env); }
switch (env) {
  case "dev":
    currentConfig = configs["development"];
    break;
  case "prod":
    currentConfig = configs["production"];
    break;
}

// Export settings
export const EKULTUR_APP_ID = currentConfig["eKulturAppId"];
export const EKULTUR_API_GATEWAY_URL = currentConfig["eKulturApiGateway"];
export const EKULTUR_LOGIN_HOST_URL = currentConfig["eKulturLoginHost"];
export const KULTUR_API_SAMESITE = currentConfig["eKulturSameSite"];
export const PCS_API_URL = currentConfig["pcsApiUrl"];
export const PCS_API_KEY = currentConfig["pcsApiKey"];
export const SEND_AUTH_TOKEN_ON_REQUESTS = Boolean(currentConfig["sendAuthTokenOnRequests"]);

// Bypass authentication
export const BYPASS_CONFIG: any[string] = {
  "sj42Zk948da": ["Impulser"],
  "td31Xi813vu": ["Tidsrom", "Museumsloftet", "Reformasjonen"],
  "oh19xCcw4qw": ["Oppdag havet", "Båthallen", "Linge i 100", "Sally Jones"],
  "9gauzt5vk4s": ["Polar Bear"]
}

// Languages, ISO 639-1
export const LANGUAGES = [
  { key: "no", name: "Norsk"},
  { key: "sv", name: "Svenska"},
  { key: "en", name: "English"}
];